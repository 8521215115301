// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.max-width { max-width: 500px; margin: 0px auto; }
.btn { padding: 6px 50px; background: #000; border: 0px; }
.form-control { height: 55px; border-radius: 0px; border: 1px solid #e9e9e9; height: 50px; line-height: 50px; }
.vh-100{ min-height: 100vh;}
.invalid-credential{color: var(--bs-form-invalid-color);}`, "",{"version":3,"sources":["webpack://./src/components/login/css/login.css"],"names":[],"mappings":"AAAA,aAAa,gBAAgB,EAAE,gBAAgB,EAAE;AACjD,OAAO,iBAAiB,EAAE,gBAAgB,EAAE,WAAW,EAAE;AACzD,gBAAgB,YAAY,EAAE,kBAAkB,EAAE,yBAAyB,EAAE,YAAY,EAAE,iBAAiB,EAAE;AAC9G,SAAS,iBAAiB,CAAC;AAC3B,oBAAoB,mCAAmC,CAAC","sourcesContent":[".max-width { max-width: 500px; margin: 0px auto; }\n.btn { padding: 6px 50px; background: #000; border: 0px; }\n.form-control { height: 55px; border-radius: 0px; border: 1px solid #e9e9e9; height: 50px; line-height: 50px; }\n.vh-100{ min-height: 100vh;}\n.invalid-credential{color: var(--bs-form-invalid-color);}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
