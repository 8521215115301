import React from 'react';
import Row from 'react-bootstrap/Row';
import rightlogoimage from '../../assets/images/rm-logo.png';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
function Header() {
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      await axios.post('https://return-manager-backend.store-and-supply.com/admin/logout');
      localStorage.removeItem("isLoggedIn");
      navigate("/login");
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <Row className="align-items-center justify-content-center p-3 header">
      <div className="col-6 col-sm-6 col-md-6 align-items-center row-cols-12 ">
        <img className="img-fluid p-0 " src={rightlogoimage} alt="logo" width="100%" height="auto" style={{ maxWidth: '200px' }} />
      </div>
      <div className="col-6 col-sm-6 col-md-6 align-items-center row-cols-12 text-end ">
        <button className='btn btn-primary logout' onClick={handleLogout}>Déconnexion</button>
      </div>
    </Row>
  );
}

export default Header;
