import Row from 'react-bootstrap/Row';
import leftimage from './image/login-left.png';
import rightlogoimage from './image/rm-logo.png';
import React, { useContext, useEffect, useState } from 'react';
import './css/login.css';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { OrderContext } from '../../context/orders';

const authUser = () => {
  const user = localStorage.getItem("isLoggedIn");
  return user;
};

function Login() {
  let isAuthUser = authUser();
  const navigate = useNavigate();
  const [loginData, setLoginData] = useState({
    username: '',
    password: '',
  });

  const { setStoreId} = useContext(OrderContext);
  const [errors, setErrors] = useState({
    username: '',
    password: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLoginData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // Clear validation errors when the user types
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
  };

  useEffect(() => {
    isAuthUser ? navigate("/") : navigate("/login");
  }, [isAuthUser, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    // Simple validation
    if (!loginData.username.trim()) {
      errors.username = 'Username is required';
    }
    if (!loginData.password.trim()) {
      errors.password = 'Password is required';
    }
    if (Object.keys(errors).length > 0 && (errors.username || errors.password)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        errors
      }));
    } else {
        const response = await axios.post(`${process.env.REACT_APP_STORE_URL}/admin/login`, loginData);
        if (response.status === 200) {
          localStorage.setItem("isLoggedIn", 1);
          localStorage.setItem("storeid", response.data.storeid);
          setStoreId(response.data.storeid);
          navigate("/");
        } else {
          errors.message = response.data.message;
          errors.username = '';
          errors.password = '';
          setErrors((prevErrors) => ({
            ...prevErrors,
            errors
          }));
        }
    }
  };

  return (
    <Row className=" align-items-center justify-content-center m-0" > 
     <div className="col-md-6 align-items-center row-cols-12 p-0 d-none d-md-block ">
        <img className="img-fluid p-0 vh-100" src={leftimage} alt="" width="100%" height="auto" />
     </div>
     <div className="col-md-6 align-items-center row-cols-12 ">
        <div className="align-middle max-width h-100">
            <img className="img-fluid p-0 " src={rightlogoimage} alt="logo" width="100%" height="auto" style={{maxWidth:'393px'}}/>
              {errors.message && (
                <div className="invalid-credential mt-5">{errors.message}</div>
              )}
            <form onSubmit={handleSubmit} autoComplete="off" className="mt-5">
            <div className="form-group my-4">
              <label htmlFor="username">Email ID</label>
              <input
                type="text"
                className={`form-control ${errors.username ? 'is-invalid' : ''}`}
                id="username"
                name="username"
                 placeholder ="Username"
                value={loginData.username}
                onChange={handleChange}
              />
              {errors.username && (
                <div className="invalid-feedback">{errors.username}</div>
              )}
            </div>
            <div className="form-group my-4">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                id="password"
                name="password"
                placeholder ="Password"
                value={loginData.password}
                onChange={handleChange}
              />
              {errors.password && (
                <div className="invalid-feedback">{errors.password}</div>
              )}
            </div>
            <button type="submit" className="btn btn-primary my-2 rounded-pill">
              Submit
            </button>
          </form>
       </div>
     </div>
    </Row>
  );
}

export default Login;

