import React from 'react'
import { NavLink } from "react-router-dom";

export default function NavLinks() {
    return (
        <>
            <NavLink className="d-flex p-4" to="/">
            Commandes
            </NavLink>
            <NavLink className="d-flex p-4" to="/compliant">
            Retours conformes
            </NavLink>
            <NavLink className="d-flex p-4" to="/noncompliant">
            Retours non conformes
            </NavLink>
        </>
    )
}


