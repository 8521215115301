import React, { useContext } from 'react';
import Row from 'react-bootstrap/Row';
import { Header, NavLinks, ListOrders } from "../common"
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { OrderContext } from '../../context/orders';

const authUser = () => {
  const user = localStorage.getItem("isLoggedIn");
  return user;
};

function Compliant() {

  let isAuthUser = authUser();
  const [ordersData, setOrdersData] = React.useState([]);
  const navigate = useNavigate();
  const {storeId, setStoreId} = useContext(OrderContext)
  console.log(storeId);
  React.useEffect(() => {
    if (!storeId) {
      const storedStoreId = localStorage.getItem("storeid");
      if (storedStoreId) {
        setStoreId(storedStoreId);
      }
    }
  }, [storeId, setStoreId]);
  const fetchData = async () => {
      const response = await axios.post(`${process.env.REACT_APP_STORE_URL}/orders/compliant`,{ storeId }); 
      if(response.data.orders) {
        setOrdersData(response.data.orders); 
      }
  };

  React.useEffect(() => {
    isAuthUser ? navigate("/compliant") : navigate("/login");
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthUser, storeId]);


  return (
    <Row className="allorder m-0">
      <Header />
      <Row className="flex-wrap mainsection">
        <div className="col-md-2 row-cols-12 left-panel p-0">
          <NavLinks/>
        </div>
        <div className="col-md-10 align-items-center row-cols-12 p-0">
          <h2 className="h2 p-4 m-0">RETOURS CONFORMES</h2>
            <ListOrders ordersData={ordersData} />
        </div>
      </Row>
    </Row>
  );
}

export default Compliant;