import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './components/login/login';
import Allorders from './components/orders/allorders';
import Compliant from './components/compliant/compliant';
import NonCompliant from './components/noncompliant/noncompliant';
import PrivateRoute from "./routes/PrivateRoute";
import './App.css';
import './assets/css/main.css'
import { OrderProvider } from './context/orders';

function App() {
  return (
    <OrderProvider>
      <Router>
        <TitleUpdater />
        <Routes>
          <Route element={<PrivateRoute element={<Allorders />} />}>
            <Route exact path="*" element={<Allorders />} />
            <Route exact path="/compliant" element={<Compliant />} />
            <Route exact path="/noncompliant" element={<NonCompliant />} />
          </Route>
          <Route exact path="/login" element={<Login />} />
        </Routes>
      </Router>
    </OrderProvider>
  );
}

function TitleUpdater() {
  const location = useLocation();

  React.useEffect(() => {
    const pathname = location.pathname.replace('/', '');
    document.title = !pathname ? 'Orders' : `${pathname.charAt(0).toUpperCase()}${pathname.slice(1)}`;
  }, [location]);

  return null;
}

export default App;
